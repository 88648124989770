import { BrowserRouter, Routes, Route } from 'react-router-dom';
import SignIn from './pages/signin/SignIn';
import Home from './pages/home/Home';
import Clients from './pages/clients/Clients';
import NewClient from './pages/new-clients/NewClient';
import Visits from './pages/visits/Visits';
import { Navigate } from 'react-router-dom'
import { UserContext } from './context/Context';
import {useEffect, useState} from 'react'
import Thanks from './pages/new-clients/Thanks';
import Client from './pages/client/Client';
import NewVisit from './pages/new-visit/NewVisit';
import Visit from './pages/visit/Visit';
import UpcomingVisits from './pages/upcoming-visits/UpcomingVisits';
import Archives from './pages/archives/Archives';
import Admin from './pages/admin/Admin';

function App() {


  const [context, setContext] = useState(null)

  useEffect(() => {
    const token = localStorage.getItem('token')

    if (token){
      fetch('https://hfmkenya.com/api/get-user', {
        method: "GET",
        headers: {
          Authorization: `Token ${token}`,
        }
      })
      .then(res => {
        res.json()
        .then(user => {
          setContext(user)
        })
        
      })
    } else{
      setContext('')
    }
  }, [])

  return (
    <UserContext.Provider value={[context, setContext]}>
      {context !== null &&
        <BrowserRouter>
        {context !== '' ?
        <Routes>
          <Route exact path="/" element={<Home/>}/>
          <Route exact path="/login" element={<SignIn/>}/>
          <Route exact path="/home" element={<Home/>}/>
          <Route exact path="/clients" element={<Clients/>} />
          <Route exact path="/new-client" element={<NewClient/>}/>
          <Route exact path="/thanks" element={<Thanks/>}/>
          <Route exact path="/visits" element={<Visits/>}/>
          <Route exact path="/client" element={<Client/>}/>
          <Route exact path="/new-visit" element={<NewVisit/>}/>
          <Route exact path="/visit" element={<Visit/>}/>
          <Route exact path="/upcoming-visits" element={<UpcomingVisits/>}/>
          <Route exact path="/archives" element={<Archives/>}/>
          <Route exact path="/admin" element={<Admin/>}/>
        </Routes>
        :
        <Routes>
        <Route path="*" element={<Navigate to="/login" replace/>}/>
        <Route exact path="/login" element={<SignIn/>}/>
        <Route exact path="/new-client" element={<NewClient/>}/>
        <Route exact path="/thanks" element={<Thanks/>}/>
      </Routes>
      }
      </BrowserRouter>
      }
    </UserContext.Provider>
  );
}

export default App;
