import React from 'react'
import {NavLink} from 'react-router-dom'
import './sidenav.css'

function SideNav() {
  return (
    <div className='theSideNav'>
        <img id="navlogo" alt="HFM logo" src="logo.png"/>
        <ul>
            <li><NavLink exact activeClassName="active" to='/home' >Home</NavLink></li>
            <li><NavLink activeClassName="active" to='/clients' >Clients</NavLink></li>
            <li><NavLink activeClassName="active" to='/upcoming-visits' >Upcoming Visits</NavLink></li>
            <li><NavLink activeClassName="active" to='/visits' >Past Visits</NavLink></li>
            {/* <li><a href='/home'>Home</a></li>
            <li><a href='/clients'>Clients</a></li>
            <li><a href='/visits'>Visits</a></li> */}
            <li><NavLink activeClassName="active" to='/archives' >Archives</NavLink></li>
            <li><a href='#'>Payments</a></li>
        </ul>
    </div>
  )
}

export default SideNav