import React, { useEffect, useState } from 'react'
import {useSearchParams, useNavigate} from 'react-router-dom'
import Layout from '../../layouts/Layout'
import { format } from "date-fns";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft} from '@fortawesome/free-solid-svg-icons'
import './visit.css'

function Visit() {
  const [visit, setVisit] = useState({
    client: {
      first_name: "",
      last_name: ""
    }
  })
  const [searchParams] = useSearchParams()

  const navigate = useNavigate()
  
  useEffect(() => {
    const token = localStorage.getItem('token')

    let id = searchParams.get("id")

    fetch(`https://hfmkenya.com/api/visits/${id}`, {
      headers: {
        Authorization: `Token ${token}`,
      }
    })
    .then(res => res.json())
    .then(data => {
        if(data){
            setVisit(data)
        }else {
            alert('Error fetching client data. Please try again')
        }
    })
    .catch(err => alert(err))
  }, [])

  return (
    <Layout>
      <div className='visitLayout'>
          <div className='goBackVisit'>
            <FontAwesomeIcon className='icons' icon={faArrowLeft} onClick={() => navigate(-1)}/>
            <p>Go Back</p>
          </div>
        <div className='visitLayoutHeader'>
          <h3>Visit to: {visit.client.first_name} {visit.client.last_name}</h3>
          <div>
          <button>Edit Details</button>
          <button>Delete</button>
          </div>
          
        </div>

        <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col" class='w-25 border'>Category</th>
                  <th scope="col" >Details</th>
                </tr>
              </thead>
              <tbody>
                {
                  Object.keys(visit).filter(thekey => (
                    thekey !== 'client' && thekey !== 'nurse' && thekey !== 'id'))
                    .map(key => {
                      return(
                        <tr>
                          <td className='border'>{key}</td>
                          <td className='border'>
                            <ul>
                            {
                            Array.isArray(visit[key]) ? 
                              visit[key].map(item => {
                                return <li>{item}</li>
                              })
                              :
                              key === 'date' || key === 'next_visit' ?
                              <li>{format(new Date(visit[key]), "MMMM do, yyyy")}</li>
                              :
                              <li>{visit[key]}</li>
                            }
                            </ul>
                            </td>
                        </tr>
                      )
                    }
                  )
                  
                }
              </tbody>
        </table>
      </div>
    </Layout>
  )
}

export default Visit
