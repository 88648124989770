import React from 'react'

function Personal({formik}) {

//   const handleChange = (event) => {
//     setFormData({
//         ...formData,
//         [event.target.name]: event.target.value
//     })
//   }

  return (
    <div>
        <div class="mb-3">
            <label for="first-name" class="form-label">First Name</label>
            <input type="text" class="form-control" id="first_name" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.first_name} name="first_name"/>
        </div>
        {formik.touched.first_name && formik.errors.first_name ? <div style={{'color': 'red'}}>{formik.errors.first_name}</div> : null}
        <div class="mb-3">
            <label for="first-name" class="form-label">Middle Name</label>
            <input type="text" class="form-control" id="middle_name" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.middle_name} name="middle_name"/>
        </div>
        {formik.touched.middle_name && formik.errors.middle_name ? <div style={{'color': 'red'}}>{formik.errors.middle_name}</div> : null}
        <div class="mb-3">
            <label for="first-name" class="form-label">Last Name</label>
            <input type="text" class="form-control" id="last_name" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.last_name} name="last_name"/>
        </div>
        {formik.touched.last_name && formik.errors.last_name ? <div style={{'color': 'red'}}>{formik.errors.last_name}</div> : null}
        <div class="mb-3">
            <label for="first-name" class="form-label">Email</label>
            <input type="email" class="form-control" id="email" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.email} name="email"/>
        </div>
        {formik.touched.email && formik.errors.email ? <div style={{'color': 'red'}}>{formik.errors.email}</div> : null}
        <div class="mb-3">
            <label for="first-name" class="form-label">Phone Number</label>
            <input type="text" class="form-control" id="phone_number" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.phone_number} name="phone_number"/>
        </div>
        {formik.touched.phone_number && formik.errors.phone_number ? <div style={{'color': 'red'}}>{formik.errors.phone_number}</div> : null}
        <div class="mb-3">
            <label for="first-name" class="form-label">Age</label>
            <input type="text" class="form-control" id="age" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.age} name="age"/>
        </div>
        {formik.touched.age && formik.errors.age ? <div style={{'color': 'red'}}>{formik.errors.age}</div> : null}
        <div class="mb-3">
            <label for="marital-status" class="form-label">Marital Status</label>
            <select class="form-select" aria-label="Default select example" onBlur={formik.handleBlur} onChange={formik.handleChange}>
            <option selected value="Married">Married</option>
            <option value="Single">Single</option>
            <option value="Divorced">Divorced</option>
            <option value="Widowed">Widowed</option>
            </select>
        </div>
        {formik.touched.marital_status && formik.errors.marital_status ? <div style={{'color': 'red'}}>{formik.errors.marital_status}</div> : null}
        <div class="mb-3">
            <label for="first-name" class="form-label">Place of Residence</label>
            <input type="text" class="form-control" id="location" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.location} name="location"/>
        </div>
        {formik.touched.location && formik.errors.location ? <div style={{'color': 'red'}}>{formik.errors.location}</div> : null}
    </div>
  )
}

export default Personal