import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import {createSearchParams, useNavigate} from "react-router-dom"
import Layout from '../../layouts/Layout'
import '../visits/visits.css'
import { format } from "date-fns";
import { Modal } from 'react-bootstrap';

function UpcomingVisits() {
    const navigate = useNavigate()
    const [visits, setVisits] = useState([])
    const [singleVisit, setSingleVisit] = useState({
        date: "01-01-2022",
        client: {
            location: ""
        },
        next_visit: "01-01-2022"
        })
    const [show, setShow] = useState(false);
        
    const token = localStorage.getItem('token')

    useEffect(() => {
    fetch('https://hfmkenya.com/api/upcomingvisits', {
        headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'application/json'
        }
    })
    .then((res) => res.json())
    .then(data => {
        if(data){
            setVisits(data.visits)
        }
    })
    .catch(err => console.log(err))
    }, [])

    const handleClose = () => setShow(false);

    function handleShow(id) {
    fetch(`https://hfmkenya.com/api/visits/${id}`, {
        headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'application/json'
        }
    })
    .then(res => res.json())
    .then(data => {
        setSingleVisit(data)
    })
    setShow(true);
    }

    const handleVisitClick = (id) => {

    navigate({
        pathname: `/client`,
        search: createSearchParams({
        id: id
        }).toString()
    })
    }
    return (
        <Layout>
        <div className='visits-layout'>
        <div className='home-top'>
            <h3>Look Out For The Following Upcoming Visits</h3>
        </div>
        <div className='visit-cards'>
        {visits.map((visit) => {return(
            <div class="card" style={{"width": "15rem"}} key={visit.id} onClick={() => handleShow(visit.id)}>
            <div class="card-body">
                <h5 class="card-title">{format(new Date(visit.next_visit), "MMMM do, yyyy")}</h5>
            </div>
            </div>
        )
        })}
        </div>
        </div>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title><strong>{format(new Date(singleVisit.next_visit), "MMMM do, yyyy")}</strong></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p><strong>Visit to: </strong>{singleVisit.client.first_name} {singleVisit.client.last_name}</p>
                <p><strong>Location: </strong>{singleVisit.client.location}</p>
            </Modal.Body>
            <Modal.Footer>
                <p className='modal-footer' onClick={() => handleVisitClick(singleVisit.client.id)}>See Client Details</p>
            </Modal.Footer>
        </Modal>
        </Layout>
    )
}

export default UpcomingVisits
