import React, {useEffect, useState} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faArrowLeft} from '@fortawesome/free-solid-svg-icons'
import './new-visit.css'
import Layout from '../../layouts/Layout'

function NewVisit() {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  
  const [formData, setFormData] = useState({
    client: "",
    date: "",
    complaints: [""],
    good_reports: [""],
    needs_identified: [""],
    solutions: [""],
    other: [""],
    assignments: [""],
    next_visit: ""
  })

  useEffect(() => {
    let id = searchParams.get("id")

    setFormData({
      ...formData,
      client: id
    })
  }, [])

//   const handleFieldChange = (e) => {
//     if (e.target.name === "complaint"){
//         let complaints = [...formData.complaints]
//         complaints.push(e.target.value)
//         setFormData({complaints})
//     }
//   }

  // Complaints Section
  const addComplaint = (e) => {
    e.preventDefault()
    const new_complaints = [...formData.complaints]
    new_complaints.push("")
    setFormData({...formData,
        complaints: new_complaints
    })
  }

  const handleComplaintChange = (index, e) => {
    let data = [...formData.complaints]
    data[index] = e.target.value
    setFormData({...formData,
        complaints: data})
  }

  const removeComplaint = (e, index) => {
    e.preventDefault()
    let data = [...formData.complaints]
    data.splice(index, 1)

    setFormData({...formData,
        complaints: data 
    })
  }

  //Positive reports Section
  const addGoodReport = (e) => {
    e.preventDefault()
    const new_reports = [...formData.good_reports]
    new_reports.push("")
    setFormData({...formData,
      good_reports: new_reports
    })
  }

  const handleGoodReportsChange = (index, e) => {
    let data = [...formData.good_reports]
    data[index] = e.target.value
    setFormData({...formData,
      good_reports: data})
  }

  const removeGoodReport = (e, index) => {
    e.preventDefault()
    let data = [...formData.good_reports]
    data.splice(index, 1)

    setFormData({...formData,
      good_reports: data 
    })
  }

  //Needs Identified section
  const addNeed = (e) => {
    e.preventDefault()
    const new_needs = [...formData.needs_identified]
    new_needs.push("")
    setFormData({...formData,
      needs_identified: new_needs
    })
  }

  const handleNeedsChange = (index, e) => {
    let data = [...formData.needs_identified]
    data[index] = e.target.value
    setFormData({...formData,
      needs_identified: data})
  }

  const removeNeed = (e, index) => {
    e.preventDefault()
    let data = [...formData.needs_identified]
    data.splice(index, 1)

    setFormData({...formData,
      needs_identified: data 
    })
  }

  // Solutions section
  const addSolution = (e) => {
    e.preventDefault()
    const new_sols = [...formData.solutions]
    new_sols.push("")
    setFormData({...formData,
      solutions: new_sols
    })
  }

  const handleSolutionsChange = (index, e) => {
    let data = [...formData.solutions]
    data[index] = e.target.value
    setFormData({...formData,
      solutions: data})
  }

  const removeSolution = (e, index) => {
    e.preventDefault()
    let data = [...formData.solutions]
    data.splice(index, 1)

    setFormData({...formData,
      solutions: data 
    })
  }

  // Assignments Section
  const addAssignment = (e) => {
    e.preventDefault()
    const new_asmnt = [...formData.assignments]
    new_asmnt.push("")
    setFormData({...formData,
      assignments: new_asmnt
    })
  }

  const handleAssignmentsChange = (index, e) => {
    let data = [...formData.assignments]
    data[index] = e.target.value
    setFormData({...formData,
      assignments: data})
  }

  const removeAssignment = (e, index) => {
    e.preventDefault()
    let data = [...formData.assignments]
    data.splice(index, 1)

    setFormData({...formData,
      assignments: data 
    })
  }

  // Other info section
  const addOther = (e) => {
    e.preventDefault()
    const new_other = [...formData.other]
    new_other.push("")
    setFormData({...formData,
      other: new_other
    })
  }

  const handleOthersChange = (index, e) => {
    let data = [...formData.other]
    data[index] = e.target.value
    setFormData({...formData,
      other: data})
  }

  const removeOther = (e, index) => {
    e.preventDefault()
    let data = [...formData.other]
    data.splice(index, 1)

    setFormData({...formData,
      other: data 
    })
  }

  // Dates section
  const handleDatesChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name] : e.target.value
    })
  }

  // Submission logic
  const handleSubmit = (e) => {
    e.preventDefault()

    const token = localStorage.getItem('token')
    console.log(formData)

    fetch('https://hfmkenya.com/api/visits', {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'visit': formData
      })
    })
    .then(res => res.json())
    .then(data => {
      if (data.visit){
        navigate(-1)
      } else{
        alert('Error completing visit details')
      }
    })
    .catch(err => alert('Looks like something went wrong, please try again later'))
  }

  return (
    <Layout>
    <div className='newVisitLayout'>
          <div className='goBack'>
            <FontAwesomeIcon className='icons' icon={faArrowLeft} onClick={() => navigate(-1)}/>
            <p>Go Back</p>
          </div>
        <h3>NEW VISIT DETAILS</h3>
        <form>
            <div class="input-group mb-3">
                <span class="input-group-text">Date:</span>
                <input type="date" class="form-control" id="date" name="date" aria-describedby="emailHelp" value={formData.date} onChange={handleDatesChange}/>
            </div>
            <h4>Complaints:</h4>
            {
                formData.complaints.map((complaint, index) => {
                    return (
                        <div class="input-group mb-3" key={index}>
                        <textarea type="text" class="form-control" name="complaints" onChange={(e) => handleComplaintChange(index, e)} value={complaint}></textarea>
                        <FontAwesomeIcon onClick={(e) => removeComplaint(e, index)} icon={faXmark} className="removeIcon"/>
                        </div>
                    )
                })
            }
            <button className="btn btn-primary" onClick={(e) => addComplaint(e)}>Add Complaint</button>
            <h4>Positive Reports:</h4>
            {
              formData.good_reports.map((report, index) => {
                return(
                  <div class="input-group mb-3" key={index}>
                  <textarea type="text" class="form-control" name="good_reports" onChange={(e) => handleGoodReportsChange(index, e)} value={report}></textarea>
                  <FontAwesomeIcon onClick={(e) => removeGoodReport(e, index)} icon={faXmark} className="removeIcon"/>
                  </div>
                )
              })
            }
            <button className="btn btn-primary" onClick={(e) => addGoodReport(e)}>Add Good Report</button>
            <h4>Needs Identified:</h4>
            {
              formData.needs_identified.map((need, index) => {
                return(
                  <div class="input-group mb-3" key={index}>
                  <textarea type="text" class="form-control" name="needs_identified" onChange={(e) => handleNeedsChange(index, e)} value={need}></textarea>
                  <FontAwesomeIcon onClick={(e) => removeNeed(e, index)} icon={faXmark} className="removeIcon"/>
                  </div>
                )
              })
            }
            <button className="btn btn-primary" onClick={(e) => addNeed(e)}>Add Need Identified</button>
            <h4>Solutions:</h4>
            {
              formData.solutions.map((solution, index) => {
                return(
                  <div class="input-group mb-3" key={index}>
                  <textarea type="text" class="form-control" name="solutions" onChange={(e) => handleSolutionsChange(index, e)} value={solution}></textarea>
                  <FontAwesomeIcon onClick={(e) => removeSolution(e, index)} icon={faXmark} className="removeIcon"/>
                  </div>
                )
              })
            }
            <button className="btn btn-primary" onClick={(e) => addSolution(e)}>Add Solution</button>
            <h4>Assignments:</h4>
            {
              formData.assignments.map((assignment, index) => {
                return (
                  <div class="input-group mb-3" key={index}>
                  <textarea type="text" class="form-control" name="assignments" onChange={(e) => handleAssignmentsChange(index, e)} value={assignment}></textarea>
                  <FontAwesomeIcon onClick={(e) => removeAssignment(e, index)} icon={faXmark} className="removeIcon"/>
                  </div>
                )
              })
            }
            <button className="btn btn-primary" onClick={(e) => addAssignment(e)}>Add Assignment</button>
            <h4>Any Other Info:</h4>
            {
              formData.other.map((other, index) => {
                return (
                  <div class="input-group mb-3" key={index}>
                  <textarea type="text" class="form-control" name="other" onChange={(e) => handleOthersChange(index, e)} value={other}></textarea>
                  <FontAwesomeIcon onClick={(e) => removeOther(e, index)} icon={faXmark} className="removeIcon"/>
                  </div>
                )
              })
            }
            <button className="btn btn-primary" onClick={(e) => addOther(e)}>Add Other Info</button>
            <h4>Next Visit:</h4>
            <div class="input-group mb-3">
                <span class="input-group-text">Date:</span>
                <input type="date" class="form-control" id="next_visit" name="next_visit" aria-describedby="emailHelp" value={formData.next_visit} onChange={handleDatesChange}/>
            </div>
            <div id="newVisitSubmit" >
            <button type="submit" className="btn btn-success" onClick={handleSubmit}>Complete</button>
            </div>
            
        </form>
    </div>
    </Layout>
  )
}

export default NewVisit
