import React from 'react'

function Medical({formik}) {

    // const handleChange = (event) => {
    //     setFormData({
    //         ...formData,
    //         [event.target.name]: event.target.value
    //     })
    //   }
      return (
        <div>
            <div class="mb-3">
                <label for="first-name" class="form-label">Current Pregnancy Duration in weeks</label>
                <input type="text" class="form-control" id="pregnancy_weeks" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.pregnancy_weeks} name="pregnancy_weeks"/>
            </div>
            {formik.touched.pregancy_weeks && formik.errors.pregancy_weeks ? <div style={{'color': 'red'}}>{formik.errors.pregancy_weeks}</div> : null}
            <div class="mb-3">
                <label for="first-name" class="form-label">EDD</label>
                <input type="date" class="form-control" id="EDD" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.EDD} name="EDD"/>
            </div>
            {formik.touched.EDD && formik.errors.EDD ? <div style={{'color': 'red'}}>{formik.errors.EDD}</div> : null}
            <div class="mb-3">
                <label for="first-name" class="form-label">Number of previous pregnancies</label>
                <input type="text" class="form-control" id="previous_pregnancies" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.previous_pregnancies} name="previous_pregnancies"/>
            </div>
            {formik.touched.previous_pregnancies && formik.errors.previous_pregnancies ? <div style={{'color': 'red'}}>{formik.errors.previous_pregnancies}</div> : null}
            <div class="mb-3">
                <label for="first-name" class="form-label">Gravida</label>
                <input type="text" class="form-control" id="gravida" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.gravida} name="gravida"/>
            </div>
            {formik.touched.gravida && formik.errors.gravida ? <div style={{'color': 'red'}}>{formik.errors.gravida}</div> : null}
            <div class="mb-3">
                <label for="first-name" class="form-label">LMP</label>
                <input type="text" class="form-control" id="LMP" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.LMP} name="LMP"/>
            </div>
            {formik.touched.LMP && formik.errors.LMP ? <div style={{'color': 'red'}}>{formik.errors.LMP}</div> : null}
            <div class="mb-3">
                <label for="first-name" class="form-label"> Parity</label>
                <input type="text" class="form-control" id="parity" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.parity} name="parity"/>
            </div>
            {formik.touched.parity && formik.errors.parity ? <div style={{'color': 'red'}}>{formik.errors.parity}</div> : null}
        </div>
      )
}

export default Medical