import React, {useEffect, useState} from 'react'
import Layout from '../../layouts/Layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faArchive} from '@fortawesome/free-solid-svg-icons'
import './clients.css'
import {createSearchParams, useNavigate} from 'react-router-dom'
import { Modal } from 'react-bootstrap';



function Clients() {

  const navigate = useNavigate()

  const [clients, setClients] = useState([])
  const [filteredClients, setFilteredClients] = useState(clients)
  const [show, setShow] = useState(false);
  const [clientId, setClientId] = useState('')

  useEffect(() => {
    const token = localStorage.getItem('token')

    fetch('https://hfmkenya.com/api/clients', {
      headers: {
        Authorization: `Token ${token}`,
      }
    })
    .then(res => res.json())
    .then(data => {
      if (data.clients){
        setClients(data.clients)
        setFilteredClients(data.clients)
      }
    })
    .catch(err => console.log(err))
  }, [])


  const handleEditClick = (id) => {

    navigate({
      pathname: "/client",
      search: createSearchParams({
        id: id
      }).toString()
    })
  }

  const handleArchiveClick = (id) => {
    const token = localStorage.getItem('token')
    let today = Date.now()

    fetch(`https://hfmkenya.com/api/clients/${id}`, {
      method: "PATCH",
      headers: {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        isActive: false,
        archivedAt: today
      })
    })
    .then(res => res.json())
    .then(data => {
      if(data){
        let filteredData = clients.filter((client) => data.id !== client.id)
        setFilteredClients(filteredData)
        setShow(false)
      }
    })
  }

  const handleClose = () => setShow(false);

  function handleShow(id) {
    setClientId(id)
    setShow(true);

  }

  return (
    <Layout>
        <div className='clients'>
            <div className='home-top'>
                <h3>Active Clients</h3>
            </div>
            <div className='client-nav'>
                <input type='text' name='value' placeholder='Search'/>
                <button onClick={() => navigate("/new-client")}>New Client</button>
            </div>
            <div className='client-body'>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Client No.</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone Number</th>
                  <th scope="col">Location</th>
                  <th scope="col">Marital Status</th>
                  <th scope="col">Age</th>
                  <th scope="col">Category</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                  filteredClients.map(client => {
                    return(
                      <tr id={client.id} key={client.id}>
                        <td>{client.client_no}</td>
                        <td>{client.first_name} {client.middle_name} {client.last_name}</td>
                        <td>{client.email}</td>
                        <td>{client.phone_number}</td>
                        <td>{client.location}</td>
                        <td>{client.marital_status}</td>
                        <td>{client.age} Yrs</td>
                        <td>{client.category}</td>
                        <td>
                          <FontAwesomeIcon title='View' className='actionIcons' icon={faEye} onClick={() => handleEditClick(client.id)}/>
                          <FontAwesomeIcon title='Archive' className='actionIcons' icon={faArchive} onClick={() => handleShow(client.id)}/>
                          </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
            </div>
        </div>

        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
                <h5>Are you sure you want to Archive this client?</h5>
                <p>(Archived clients will no longer appear on the active clients list.)</p>
                <div className='archiveButtons'>
                  <button style={{'background-color': 'green'}} onClick={() => handleArchiveClick(clientId)}>Yes</button>
                  <button style={{'background-color': 'red'}} onClick={() => handleClose()}>No</button>
                </div>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
    </Modal>
    </Layout>
  )
}

export default Clients
