import React from 'react'
import SideNav from '../components/SideNav'
import './layout.css'

function Layout({children}) {
  return (
    <div id="layout">
        <div className='layout-left'>
          <SideNav/>
        </div>
        <div className='layout-right'>
          <main>{children}</main>
        </div>
    </div>
  )
}

export default Layout