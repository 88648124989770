import React from 'react'

function Additional({formik}) {

    // const handleChange = (event) => {
    //     setFormData({
    //         ...formData,
    //         [event.target.name]: event.target.value
    //     })
    // }
    return (
        <div>
            <div class="mb-3">
                <label for="first-name" class="form-label">Next of Kin Name</label>
                <input type="text" class="form-control" id="next_of_kin_name" onChange={formik.handleChange} value={formik.values.next_of_kin_name} name="next_of_kin_name"/>
            </div>
            {formik.touched.next_of_kin_name && formik.errors.next_of_kin_name ? <div style={{'color': 'red'}}>{formik.errors.next_of_kin_name}</div> : null}
            <div class="mb-3">
                <label for="first-name" class="form-label">Next of Kin Phone Number</label>
                <input type="text" class="form-control" id="next_of_kin_number" onChange={formik.handleChange} value={formik.values.next_of_kin_number} name="next_of_kin_number"/>
            </div>
            {formik.touched.next_of_kin_number && formik.errors.next_of_kin_number ? <div style={{'color': 'red'}}>{formik.errors.next_of_kin_number}</div> : null}
            <div class="mb-3">
                <label for="first-name" class="form-label">Hobbies</label>
                <textarea type="text" class="form-control" id="hobbies" onChange={formik.handleChange} value={formik.values.hobbies} name="hobbies"/>
            </div>
            {formik.touched.hobbies && formik.errors.hobbies ? <div style={{'color': 'red'}}>{formik.errors.hobbies}</div> : null}
            <div class="mb-3">
                <label for="first-name" class="form-label">Please indicate any underlying Conditions you have</label>
                <textarea type="text" class="form-control" placeholder='E.g. Diabetes / Allergies' id="underlying_conditions" onChange={formik.handleChange} value={formik.values.underlying_conditions} name="underlying_conditions"/>
            </div>
            {formik.touched.underlying_conditions && formik.errors.underlying_conditions ? <div style={{'color': 'red'}}>{formik.errors.underlying_conditions}</div> : null}
            <div class="mb-3">
                <label for="first-name" class="form-label">Please describe any notable medical history</label>
                <textarea type="text" class="form-control" placeholder='E.g. I have had a minor surgery' id="history" onChange={formik.handleChange} value={formik.values.history} name="history"/>
            </div>
            {formik.touched.history && formik.errors.history ? <div style={{'color': 'red'}}>{formik.errors.history}</div> : null}
        </div>
    )
}

export default Additional