import React, {useState, useContext} from 'react'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../../context/Context'
import './signin.css'


function SignIn() {

  const [context, setContext] = useContext(UserContext)
  
  const [formData, setFormData ]= useState({
    username: "",
    password: ""

  })

  const handleChange = (e) => {
    setFormData({
        ...formData,
        [e.target.name]: e.target.value
    })
  }

  let navigate = useNavigate()

  const handleSubmit = (e) => {
    e.preventDefault()

    fetch('https://hfmkenya.com/api-token-auth', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
    })
    .then(res => res.json())
    .then(data => {
        if(data.token){
            localStorage.setItem('token', data.token)
            setContext('user')
            navigate("/home")
        }else{
            alert('Invalid credentials')
        }
    })
    .catch(error => {
        console.error('Inavalid credentials')
    })
  }

  return (
    <div className='login'>
        <div className='login-left'>
            <img alt="HFM Logo" src="logo.png"/>
        </div>
        <div className='login-right'>
            <h3>Welcome back to HFM</h3>
            <p>Sign in to your account</p>
            <form>
            <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">Username</label>
                <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" value={formData.username} onChange={handleChange} name="username"/>
            </div>
            <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">Password</label>
                <input type="password" class="form-control" id="exampleInputPassword1" value={formData.password} onChange={handleChange} name="password"/>
            </div>
            <button type="submit" class="btn btn-primary" onClick={handleSubmit}>Sign In</button>
            </form>
            <p style={{color: 'blue'}}>Don't have an account? Contact admin for assistance</p>
        </div>
    </div>
  )
}

export default SignIn
