import React from 'react'
import Layout from '../../layouts/Layout'
import './home.css'
import {useNavigate} from 'react-router-dom'
import { useEffect } from 'react'
import { useState } from 'react'

function Home() {

  const navigate = useNavigate()
  const [user, setUser] = useState({})

  useEffect(() => {
    const token = localStorage.getItem('token')

    fetch('https://hfmkenya.com/api/get-user', {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      }
    })
    .then(res => {
      res.json()
      .then(user => {
        setUser(user)
      })
      
    })
  }, [])
  
  return (
    <Layout>
      <div className='home'>
        <div className='home-top'>
          <h3>Welcome back {user.username}</h3>
        </div>
        <div className='home-bottom'>
        </div>
      </div>
    </Layout>
  )
};

export default Home
