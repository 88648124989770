import React, {useEffect, useState} from 'react'
import Layout from '../../layouts/Layout'
import '../clients/clients.css'
import './archives.css'
import { format } from "date-fns";

function Archives() {
    const [clients, setClients] = useState([])
    const [filteredClients, setFilteredClients] = useState(clients)
  
    useEffect(() => {
        const token = localStorage.getItem('token')
    
        fetch('https://hfmkenya.com/api/archives', {
          headers: {
            Authorization: `Token ${token}`,
          }
        })
        .then(res => res.json())
        .then(data => {
          if (data.clients){
            setClients(data.clients)
            setFilteredClients(data.clients)
          }
        })
        .catch(err => console.log(err))
      }, [])

  return (
    <Layout>
         <div className='archived-home-top'>
             <h3>Here Are Your Past Clients</h3>
             <br/>
             <p>If you would like to re-activate an archived client please contact the admin.</p>
        </div>
        <div className='client-body'>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Client No.</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone Number</th>
                  <th scope="col">Location</th>
                  <th scope="col">Marital Status</th>
                  <th scope="col">Age</th>
                  <th scope="col">Onboarded on</th>
                  <th scope="col">Active Until</th>
                </tr>
              </thead>
              <tbody>
                {
                  filteredClients.map(client => {
                    return(
                      <tr id={client.id} key={client.id}>
                        <td>{client.client_no}</td>
                        <td>{client.first_name} {client.middle_name} {client.last_name}</td>
                        <td>{client.email}</td>
                        <td>{client.phone_number}</td>
                        <td>{client.location}</td>
                        <td>{client.marital_status}</td>
                        <td>{client.age} Yrs</td>
                        <td>{format(new Date(client.created_at), "MMMM do, yyyy")}</td>
                        <td>{client.archivedAt == null ? 'kk' : format(new Date(parseInt(client.archivedAt)), "MMMM do, yyyy")}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
        </div>
    </Layout>
  )
}

export default Archives
