import React, {useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faArrowLeft} from '@fortawesome/free-solid-svg-icons'
import {useNavigate} from 'react-router-dom'
import Personal from './Personal'
import './new-client.css'
import Additional from './Additional'
import Medical from './Medical'
import {useFormik} from 'formik'
import * as Yup from 'yup'

function NewClient() {

  const categories = ['Personal Details', 'Additional Information', 'Pregnancy Details']

  let navigate = useNavigate()

  //states
  const [page, setPage] = useState(0)
  // const [formData, setFormData] = useState({
  //   email: "",
  //   first_name: "",
  //   middle_name: "",
  //   last_name: "",
  //   age: "",
  //   phone_number: "",
  //   location: "",
  //   next_of_kin_name: "",
  //   next_of_kin_number: "",
  //   hobbies: [],
  //   gravida: "",
  //   parita: "",
  //   LMP: "",
  //   pregancy_weeks: 0,
  //   EDD: "",
  //   underlying_conditions: [],
  //   history: "",
  //   marital_status: ""
  // })

  const formik = useFormik({
    initialValues:{
      email: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      age: "",
      phone_number: "",
      location: "",
      next_of_kin_name: "",
      next_of_kin_number: "",
      hobbies: "",
      gravida: "",
      parity: "",
      LMP: "",
      pregancy_weeks: 0,
      EDD: "",
      underlying_conditions: "",
      history: "",
      marital_status: "Married",
      previous_pregnancies: 0,
      client_no: "HFM - 001"
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required('*This field is required'),
      last_name: Yup.string().required('*This field is required'),
      email: Yup.string().email('Invalid email address').required('*This field is required'),
      age: Yup.number('Enter a valid number').required('*This field is required'),
      next_of_kin_name: Yup.string().required('*This field is required'),
      pregancy_weeks: Yup.number('Enter a valid number only. Eg. 10 instead of 10 weeks'),
      next_of_kin_number: Yup.number('Enter a valid number').required('*This field is required'),
      phone_number: Yup.number('Enter a valid number').required('*This field is required'),
      location: Yup.string().required('*This field is required'),
      EDD: Yup.date().required('Please pick a valid date')
    }),
    onSubmit: values => {
      // alert(JSON.stringify(values, null, 2))
      fetch('https://hfmkenya.com/api/clients', {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          'client': values
        })
      })
      .then(res => res.json())
      .then(data => {
        if (data.client){
          navigate("/thanks")
        } else{
          alert('Looks like some fields are missing. Please fill all required fields')
        }
      })
      .catch(err => alert('Looks like something went wrong, please try again later'))
    }
  })

  const displayContent = () => {
    switch(page) {
      
      case 0: return <Personal formik={formik}/>
      case 1: return <Additional formik={formik}/>
      case 2: return <Medical formik={formik}/>

      default: return <Personal formik={formik}/>
    }
  }

  const onContinue = () => {
    if (page < 2 ){
      setPage(page + 1) 
    } else{
      setPage(page)
    }
  }

  const onPrevious = () => {
    page === 0 ? navigate("/") : setPage(page - 1)
  }

  // const handleSubmit = (e) => {
  //   e.preventDefault()

  //   fetch(process.env.REACT_APP_API_URL+ 'clients', {
  //     method: "POST",
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify({
  //       'client': formData
  //     })
  //   })
  //   .then(res => res.json())
  //   .then(data => {
  //     console.log(data)

  //     alert('Thank you for registering on HFM. We will get in touch with you soon.')
  //   })
  //   .catch(err => alert('Looks like something went wrong, please try again later'))
  // }

  return (
    <div className='overallLayout'>
      <div className='onboardLayout'>
        <div className='onboardHeader'>
            <FontAwesomeIcon icon={faArrowLeft} onClick={onPrevious}/>
              <h3>H F M Onboarding</h3>
            <FontAwesomeIcon icon={faXmark}/>
        </div>
        <div className='progressBar' >
          <div style={{
            "width": page === 0 ? "33%"
            :
            page === 1 ? "67%"
            :
            "100%" 
          }}></div>
      </div>
      {
          page !== 2 ?
          <p>Please fill in the details below so that we can onboard you.</p>
          :
          <p>The following medical details will be needed to help us provide you with quality care.
            <br/>
            Please note that if unsure, some of the fields may be left blank to be filled with the help of your attending nurse later.
          </p>
        }
      <div className='onboardBody'>
        <div className='onboardForm'>
          <h3>{categories[page]}</h3>
          <form onSubmit={formik.handleSubmit}>
            {displayContent()}
          </form>
        </div>
        {page !== 2 &&
        <button onClick={onContinue}>Continue</button>
        }
        {page === 2 &&
        <button type='submit' onClick={formik.handleSubmit}>Submit</button>
        }
      </div>
    </div>
    </div>
    
  )
}

export default NewClient
