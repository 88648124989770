import React, { useEffect, useState } from 'react'
import Layout from '../../layouts/Layout'
import {useSearchParams, createSearchParams, useNavigate} from "react-router-dom"
import { format } from "date-fns";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faPencilSquare} from '@fortawesome/free-solid-svg-icons'
import ClientVisitCard from './ClientVisitCard';
import './client.css'
import { Modal } from 'react-bootstrap';
import {useFormik} from 'formik'
import * as Yup from 'yup'

function Client() {
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const [client, setClient] = useState({})
  const [upcomingVisit, setUpcomingVisit] = useState({})
  const [gestationWeeks, setGestationWeeks] = useState(0)
  const [show, setShow] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token')
    
    let id = searchParams.get("id")

    if (token){
      
      fetch(`https://hfmkenya.com/api/clients/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Token ${token}`,
        }
      })
      .then(res => res.json())
      .then(data => {
        if (data){
            setClient(data)
            let gest = getGestationInWeeks(data.EDD)
            setGestationWeeks(gest)
        } else {
            alert('Sorry, we cant seem to find that client\'s details. Please go back and try again')
        }
      })
      .catch(err => console.error(err))
    }
  }, [])

  useEffect(() => {
    const token = localStorage.getItem('token')
    
    let id = searchParams.get("id")

    fetch(`https://hfmkenya.com/api/upcomingvisits/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      }
    })
    .then(res => res.json())
    .then(data => {
      if (data.visits.length > 0){
        setUpcomingVisit(data.visits[0])
      } 
    })
    .catch(err => console.error(err))
  }, [])

  const handleNewVisit = (id) => {

    navigate({
      pathname: "/new-visit",
      search: createSearchParams({
        id: id
      }).toString()
    })
  }

  const getGestationInWeeks = (dueDate) => {
    const msWeeks = 1000*60*60*24*7

    let today = Date.now()
    let due = new Date(dueDate)
    let gestation = Math.floor((due.getTime() - today) / msWeeks)
    if (gestation > 40){
      return 0
    } else if(gestation < 0){
      return 0
    }
    return (40 - gestation)
  }


  // Logic for Edit client profile details
  const handleClose = () => setShow(false);

  function handleShow() {
    setShow(true);
  }

  const formik = useFormik({
    initialValues: client,
    // initialValues:{
    //   email: clientRef.email,
    //   first_name: clientRef.first_name,
    //   middle_name: "",
    //   last_name: "",
    //   age: "",
    //   phone_number: "",
    //   location: "",
    //   next_of_kin_name: "",
    //   next_of_kin_number: "",
    //   hobbies: "",
    //   gravida: "",
    //   parita: "",
    //   LMP: "",
    //   pregancy_weeks: 0,
    //   EDD: "",
    //   underlying_conditions: "",
    //   history: "",
    //   marital_status: "Married",
    //   previous_pregnancies: 0,
    //   client_no: "HFM - 001"
    // },
    enableReinitialize: true,
    validationSchema: Yup.object({
      first_name: Yup.string().required('*This field is required'),
      last_name: Yup.string().required('*This field is required'),
      email: Yup.string().email('Invalid email address').required('*This field is required'),
      age: Yup.number('Enter a valid number').required('*This field is required'),
      next_of_kin_name: Yup.string().required('*This field is required'),
      pregancy_weeks: Yup.number('Enter a valid number only. Eg. 10 instead of 10 weeks'),
      next_of_kin_number: Yup.number('Enter a valid number').required('*This field is required'),
      phone_number: Yup.number('Enter a valid number').required('*This field is required'),
      location: Yup.string().required('*This field is required'),
      EDD: Yup.date().required('Please pick a valid date')
    }),
    onSubmit: values => {
      // alert(JSON.stringify(values, null, 2))

      const token = localStorage.getItem('token')
      let id = searchParams.get("id")

      fetch(`${process.env.REACT_APP_API_URL}clients/${id}`, {
      method: "PATCH",
      headers: {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
      })
      .then(res => res.json())
      .then(data => {
        if (data){
          setClient(data)
          let gest = getGestationInWeeks(data.EDD)
          setGestationWeeks(gest)
          setShow(false)
        } else{
          alert('Please ensure all fields are correct and try again')
        }
      })
      .catch(err => alert('Looks like something went wrong, please try again later'))
    }
  })



  return (
    <Layout>
        <div className='clientPage'>
          <div className='goBack'>
            <FontAwesomeIcon className='icons' icon={faArrowLeft} onClick={() => navigate(-1)}/>
            <p>Go Back</p>
          </div>
          <div className='clientHeader'>
            <h1>{client.first_name} {client.middle_name}  {client.last_name}</h1>
            <h6>{client.phone_number}  |  {client.email}  |  {client.location}</h6>
          </div>
          <div className='clientBody'>
            <div className='clientProfile'>
              <div className='clientProfileHeader'>
              <h3>PROFILE</h3>
              <FontAwesomeIcon title='Update' className='actionIcons' icon={faPencilSquare} onClick={() => handleShow()}/>
              </div>
              <p><strong>CLIENT NO:</strong> {client.client_no}</p>
              <p><strong>REGISTERED ON:</strong> {client.created_at ? format(new Date(client.created_at), "MMMM do, yyyy"): ''}</p>
              <p><strong>AGE:</strong> {client.age} years</p>
              <p><strong>CATEGORY:</strong> {client.category}</p>
              <p><strong>MARITAL STATUS:</strong> {client.marital_status}</p>
              <p><strong>EDD:</strong> {client.EDD ? format(new Date(client.EDD), "MMMM do, yyyy") : 'Not Yet Established'}</p>
              <p><strong>CURRENT GESTATION:</strong> {gestationWeeks} weeks</p>
              <p><strong>GRAVIDA:</strong> {client.gravida}</p>
              <p><strong>LMP:</strong> {client.LMP}</p>
              <p><strong>PARITY:</strong> {client.parity}</p>
              <p><strong>CATEGORY:</strong> {client.category}</p>
              <p><strong>RISK RATING:</strong> {client.risk_rating}</p>
              <p><strong>PREVIOUS PREGNANCIES:</strong> {client.previous_pregnancies}</p>
              <p><strong>RISK RATING:</strong> {client.risk_rating}</p>
              <p><strong>UNDERLYING CONDITIONS:</strong> <br/> {client.underlying_conditions}</p>
              <p><strong>NOTABLE HISTORY:</strong> <br/> {client.history}</p>
              <p><strong>NEXT OF KIN:</strong> {client.next_of_kin_name}</p>
              <p><strong>NEXT OF KIN CONTACT:</strong> {client.next_of_kin_number}</p>
              <p><strong>HOBBIES:</strong> <br/> {client.hobbies}</p>
              <p><strong>DELIVERY DATE:</strong> {client.delivery_date ? format(new Date(client.delivery_date), "MMMM do, yyyy") : 'Yet To Deliver'}</p>
            </div>
            <div className='clientVisits'>
              <div className='upcomingVisit'>
                <h5>Upcoming Visit: <span>{upcomingVisit.next_visit ? format(new Date(upcomingVisit.next_visit), "MMMM do, yyyy") : 'None'}</span></h5>
              </div>
              <div className='clientVisitsHeader'>
                <h3>Past Visits</h3>
                <button onClick={() => handleNewVisit(client.id)}>New Visit</button>
              </div>
              <div className='clientVisitsCards'>
              {client.visits ? 
              client.visits.map((visit) => {
                return(
                  <ClientVisitCard visit={visit} client={client} key={visit.id}/>
                )
              })
              :
              <p>No visits</p>
            }
              </div>
              
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={formik.handleSubmit}>
            <div class="mb-3">
              <label for="first-name" class="form-label">Client No.</label>
              <input type="text" class="form-control" id="client_no" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.client_no} name="client_no"/>
              </div>
              {formik.touched.client_no && formik.errors.client_no ? <div style={{'color': 'red'}}>{formik.errors.client_no}</div> : null}
              <div class="mb-3">
              <label for="first-name" class="form-label">First Name</label>
              <input type="text" class="form-control" id="first_name" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.first_name} name="first_name"/>
              </div>
              {formik.touched.first_name && formik.errors.first_name ? <div style={{'color': 'red'}}>{formik.errors.first_name}</div> : null}
              <div class="mb-3">
                  <label for="first-name" class="form-label">Middle Name</label>
                  <input type="text" class="form-control" id="middle_name" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.middle_name} name="middle_name"/>
              </div>
              {formik.touched.middle_name && formik.errors.middle_name ? <div style={{'color': 'red'}}>{formik.errors.middle_name}</div> : null}
              <div class="mb-3">
                  <label for="first-name" class="form-label">Last Name</label>
                  <input type="text" class="form-control" id="last_name" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.last_name} name="last_name"/>
              </div>
              {formik.touched.last_name && formik.errors.last_name ? <div style={{'color': 'red'}}>{formik.errors.last_name}</div> : null}
              <div class="mb-3">
                  <label for="first-name" class="form-label">Email</label>
                  <input type="email" class="form-control" id="email" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.email} name="email"/>
              </div>
              {formik.touched.email && formik.errors.email ? <div style={{'color': 'red'}}>{formik.errors.email}</div> : null}
              <div class="mb-3">
                  <label for="first-name" class="form-label">Phone Number</label>
                  <input type="text" class="form-control" id="phone_number" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.phone_number} name="phone_number"/>
              </div>
              {formik.touched.phone_number && formik.errors.phone_number ? <div style={{'color': 'red'}}>{formik.errors.phone_number}</div> : null}
              <div class="mb-3">
                  <label for="first-name" class="form-label">Age</label>
                  <input type="text" class="form-control" id="age" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.age} name="age"/>
              </div>
              {formik.touched.age && formik.errors.age ? <div style={{'color': 'red'}}>{formik.errors.age}</div> : null}
              <div class="mb-3">
                  <label for="marital-status" class="form-label">Marital Status</label>
                  <select id="marital_status" name="marital_status" class="form-select" aria-label="Default select example" onBlur={formik.handleBlur} onChange={formik.handleChange}>
                  <option value="Married">Married</option>
                  <option value="Single">Single</option>
                  <option value="Divorced">Divorced</option>
                  <option value="Widowed">Widowed</option>
                  </select>
              </div>
              {formik.touched.marital_status && formik.errors.marital_status ? <div style={{'color': 'red'}}>{formik.errors.marital_status}</div> : null}
              <div class="mb-3">
                  <label for="first-name" class="form-label">Place of Residence</label>
                  <input type="text" class="form-control" id="location" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.location} name="location"/>
              </div>
              {formik.touched.location && formik.errors.location ? <div style={{'color': 'red'}}>{formik.errors.location}</div> : null}
              {/* <div class="mb-3">
                <label for="first-name" class="form-label">Current Pregnancy Duration in weeks</label>
                <input type="text" class="form-control" id="pregnancy_weeks" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.pregnancy_weeks} name="pregnancy_weeks"/>
            </div>
            {formik.touched.pregancy_weeks && formik.errors.pregancy_weeks ? <div style={{'color': 'red'}}>{formik.errors.pregancy_weeks}</div> : null} */}
            <div class="mb-3">
                <label for="first-name" class="form-label">EDD</label>
                <input type="date" class="form-control" id="EDD" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.EDD} name="EDD"/>
            </div>
            {formik.touched.EDD && formik.errors.EDD ? <div style={{'color': 'red'}}>{formik.errors.EDD}</div> : null}
            <div class="mb-3">
                <label for="first-name" class="form-label">Number of previous pregnancies</label>
                <input type="text" class="form-control" id="previous_pregnancies" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.previous_pregnancies} name="previous_pregnancies"/>
            </div>
            {formik.touched.previous_pregnancies && formik.errors.previous_pregnancies ? <div style={{'color': 'red'}}>{formik.errors.previous_pregnancies}</div> : null}
            <div class="mb-3">
                <label for="first-name" class="form-label">Gravida</label>
                <input type="text" class="form-control" id="gravida" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.gravida} name="gravida"/>
            </div>
            {formik.touched.gravida && formik.errors.gravida ? <div style={{'color': 'red'}}>{formik.errors.gravida}</div> : null}
            <div class="mb-3">
                <label for="first-name" class="form-label">LMP</label>
                <input type="text" class="form-control" id="LMP" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.LMP} name="LMP"/>
            </div>
            {formik.touched.LMP && formik.errors.LMP ? <div style={{'color': 'red'}}>{formik.errors.LMP}</div> : null}
            <div class="mb-3">
                <label for="first-name" class="form-label"> Parity</label>
                <input type="text" class="form-control" id="parity" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.parity} name="parita"/>
            </div>
            {formik.touched.parity && formik.errors.parity ? <div style={{'color': 'red'}}>{formik.errors.parity}</div> : null}
            <div class="mb-3">
                  <label for="category" class="form-label">CATEGORY</label>
                  <select id="category" name="category" class="form-select" aria-label="Default select example" onBlur={formik.handleBlur} onChange={formik.handleChange}>
                  <option selected value="Preconception">Preconception</option>
                  <option value="Antenatal">Antenatal</option>
                  <option value="Postnatal">Postnatal</option>
                  </select>
              </div>
              {formik.touched.category && formik.errors.category ? <div style={{'color': 'red'}}>{formik.errors.category}</div> : null}
              <div class="mb-3">
                  <label for="risk_rating" class="form-label">RISK RATING</label>
                  <select id="risk_rating" name="risk_rating" class="form-select" aria-label="Default select example" onBlur={formik.handleBlur} onChange={formik.handleChange}>
                  <option selected value="Normal">Normal</option>
                  <option value="High">High</option>
                  </select>
              </div>
              {formik.touched.risk_rating && formik.errors.risk_rating ? <div style={{'color': 'red'}}>{formik.errors.risk_rating}</div> : null}
            <div class="mb-3">
                <label for="first-name" class="form-label">Next of Kin Name</label>
                <input type="text" class="form-control" id="next_of_kin_name" onChange={formik.handleChange} value={formik.values.next_of_kin_name} name="next_of_kin_name"/>
            </div>
            {formik.touched.next_of_kin_name && formik.errors.next_of_kin_name ? <div style={{'color': 'red'}}>{formik.errors.next_of_kin_name}</div> : null}
            <div class="mb-3">
                <label for="first-name" class="form-label">Next of Kin Phone Number</label>
                <input type="text" class="form-control" id="next_of_kin_number" onChange={formik.handleChange} value={formik.values.next_of_kin_number} name="next_of_kin_number"/>
            </div>
            {formik.touched.next_of_kin_number && formik.errors.next_of_kin_number ? <div style={{'color': 'red'}}>{formik.errors.next_of_kin_number}</div> : null}
            <div class="mb-3">
                <label for="first-name" class="form-label">Hobbies</label>
                <textarea type="text" class="form-control" id="hobbies" onChange={formik.handleChange} value={formik.values.hobbies} name="hobbies"/>
            </div>
            {formik.touched.hobbies && formik.errors.hobbies ? <div style={{'color': 'red'}}>{formik.errors.hobbies}</div> : null}
            <div class="mb-3">
                <label for="first-name" class="form-label">Underlying Conditions</label>
                <textarea type="text" class="form-control" placeholder='E.g. Diabetes / Allergies' id="underlying_conditions" onChange={formik.handleChange} value={formik.values.underlying_conditions} name="underlying_conditions"/>
            </div>
            {formik.touched.underlying_conditions && formik.errors.underlying_conditions ? <div style={{'color': 'red'}}>{formik.errors.underlying_conditions}</div> : null}
            <div class="mb-3">
                <label for="first-name" class="form-label">Notable medical history</label>
                <textarea type="text" class="form-control" placeholder='E.g. I have had a minor surgery' id="history" onChange={formik.handleChange} value={formik.values.history} name="history"/>
            </div>
            {formik.touched.history && formik.errors.history ? <div style={{'color': 'red'}}>{formik.errors.history}</div> : null}
            <div class="mb-3">
                <label for="first-name" class="form-label">Delivery Date</label>
                <input type="date" class="form-control" id="delivery_date" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.delivery_date} name="delivery_date"/>
            </div>
            {formik.touched.delivery_date && formik.errors.delivery_date ? <div style={{'color': 'red'}}>{formik.errors.delivery_date}</div> : null}
            </form>
        </Modal.Body>
        <Modal.Footer>
            <button type='submit' className='modal-footer' onClick={formik.handleSubmit}>Confirm Edit</button>
        </Modal.Footer>
    </Modal>
    </Layout>
  )
}

export default Client
