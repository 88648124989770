import React from 'react'

function Thanks() {
  return (
    <div className='overallLayout'>
        <div className='thanksLayout'>
            <h4>Thank you for registering on HFM. It is a joy to help you have a beautiful and holistic pregancy experience.</h4>
            <p>You may now exit this page and wait for us to reach out to you.</p>
            <p>Cheers!</p>
        </div>
    </div>
  )
}

export default Thanks