import React from 'react'
import { format } from "date-fns";
import {createSearchParams, useNavigate} from 'react-router-dom'

function ClientVisitCard({visit, client}) {
    const navigate = useNavigate()

    // Navigate to single visit view
    const handleVisitClick = (id) => {

        navigate({
            pathname: "/visit",
            search: createSearchParams({
            id: id
            }).toString()
        })
        }

    return (
        <div>
            <div class="card" style={{"width": "15rem"}} onClick={() => handleVisitClick(visit.id)}>
            <div class="card-body">
                <h5 class="card-title">{format(new Date(visit.date), "MMMM do, yyyy")}</h5>
            </div>
            </div>
        </div>
    )
}

export default ClientVisitCard